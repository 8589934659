import {Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
import "./style.scss";
// hooks
// import useResize from "../../../../../../hooks/useResize";
// store
import Store from "../../../../stores";
import "moment/locale/ru";
moment.locale("ru");

export default observer(function Video() {
    // const {width} = useResize();
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-lottery-ng-video">
            {/* <Typography as="div" className="bl-lottery-ng-video__title">
                {_.gt(width ?? 0, 992) ? "Следите за прямым эфиром" : "Следите\nза прямым эфиром"}
            </Typography> */}
            <Typography as="div" className="bl-lottery-ng-video__container">
                <Typography as="div" className="bl-lottery-ng-video__container__title">
                    <Typography as="div" className="bl-lottery-ng-video__container__title__text">
                        {t(
                            moment(_.get(Store.lottery?.lottery, "started_at"))
                                .locale("ru")
                                .format("D MMMM")
                        )}{" "}
                        в {moment(_.get(Store.lottery?.lottery, "started_at")).format("HH:mm")}
                    </Typography>
                </Typography>
                <Typography as="div" className="bl-lottery-ng-video__container__video">
                    <iframe
                        src={_.get(Store.lottery?.lottery, "link")}
                        width="100%"
                        height="100%"
                        className="react-player"
                        frameBorder="0"
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                        rel="preload"
                    ></iframe>
                </Typography>
            </Typography>
        </Typography>
    );
});
