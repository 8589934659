import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {t} from "i18next";
import "./style.scss";
//components
import InfoCommon from "../../infoCommon";
import SocialIconsServices from "../../../../widgets/socialIconsServices";

export default function Info({
    subscription_fee,
    packet_minutes,
    packet_sms,
    packet_internet,
    name,
    id,
    price,
    group_name
}: Props) {
    return (
        <Typography as="div" className="bl-tariff-connection-info">
            <Row gutter={[0, 0]}>
                <Col xs={24} sm={8} className="col-info-packet-subtitle">
                    <Typography className="info-packet-subtitle">
                        {t("Ежемесячная плата")}
                    </Typography>
                    <Typography className="info-packet-value">
                        <span className="gold gold-price">{`${price}₽`}</span>
                        <span className="info-packet-value-gray">{t(" в месяц")}</span>
                    </Typography>
                </Col>
                <Col xs={24} sm={8}>
                    <SocialIconsServices
                        isPagePlan={true}
                        socialArrayNew={true}
                        subscriptionFee={subscription_fee}
                        group_name={group_name}
                    />
                </Col>
                <Col xs={24} sm={8} className="bl-tariff-connection-info-Common">
                    <InfoCommon
                        packet_minutes={packet_minutes}
                        packet_sms={packet_sms}
                        packet_internet={packet_internet}
                    />
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    subscription_fee: number;
    packet_minutes: number;
    packet_sms: number;
    packet_internet: number;
    name: string;
    id: number;
    price: number;
    group_name: string;
}

type Props = IProps;
