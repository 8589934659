import React, {useContext, useEffect, useState} from "react";
import {Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {Tooltip, notification} from "antd";
import "./style.scss";
// core
import {IPhone} from "../../../../../../core/models/Phones";
import {IPhonesFiltersRequest} from "../../../../../../core/models/PhonesFilters";
// components
import Phone from "./phone";
import Description from "./description";
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
import Error from "../../../../../modal/error";
import _ from "lodash";
// services
import PhoneService from "../../../../../../services/PhoneService";
import PhonesDefaultService from "../../../../../../services/PhonesDefaultService";
import ReservationsService from "../../../../../../services/ReservationsService";
import PhonesFiltersService from "../../../../../../services/PhonesFiltersService";
import UserService from "../../../../../../services/UserService";
import ButtonChatSupport from "../../../../delivery/buttonChatSupport";
import ListTariffService from "./listTariffService";
import superPng from "../../../../../../images/superbrone.svg";
import {createSearchParams, useNavigate} from "react-router-dom";
import PhonesBookingService from "../../../../../../services/PhonesBookingService";
import lightning2 from "../../../../../../images/lightning2.svg";
import npsImg from "../../../../../../images/nps_notif.svg";
import {observer} from "mobx-react";
import Store from "../../../../../../stores";

export default observer(function Info({Service, filters, gridType, props, search}: IProps) {
    const {
        phone,
        type,
        mask: {category},
        tariff: {id, name: tariffName, minutes, internet, sms},
        region: {name: regionName}
    } = props;
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();
    const [state, setState] = useState<string>("3 часа");
    const contextLayout = useContext(LayoutContext);
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const el: any = document.body.querySelector(".ant-notification-notice-close");
    const onClickWhatsApp = () => {
        (
            window.open(`https://wa.me/7${UserService.personalManager.phone}`, "_blank") as any
        ).focus();
    };

    const onClickSuper = async () => {
        navigate({
            pathname: `/reservation/${id}/${phone}`,
            search: createSearchParams({
                super: "true",
                ...(search.get("nps") ? {nps: search.get("nps")?.toString()} : {})
            }).toString()
        });
    };

    const onClick = async () => {
        try {
            const filter =
                localStorage.getItem("isFavorite") === "collection"
                    ? "collection"
                    : localStorage.getItem("isFavorite") === "true"
                    ? "favorite"
                    : "professional";
            setDisable(true);
            await ReservationsService.postReservationTurbo({
                phone: phone.toString(),
                loading: false,
                additional_data: JSON.stringify({
                    source: "store_web",
                    filter,
                    ...(Store.nps.phone ? {nps: {phone: Store.nps.phone}} : {})
                })
            });
            if (el) {
                el.click();
            }
            Service.updatePhones(PhonesFiltersService.getFormatedFilters(filters));
            notification.open({
                message: t("Номер успешно забронирован"),
                description: t(
                    `Номер забронирован на {days}, \nперейдите на вкладку "Брони и активации"`,
                    {days: state}
                ),
                placement: "bottom",
                closeIcon: undefined,
                className: "turbo-referral",
                duration: 1
            });
        } catch (e: any) {
            if (_.isEqual(e.response.status, 430)) {
                setDisable(false);
                contextModal?.setChildren(
                    <Error
                        title="Вы превысили лимит"
                        message={
                            <Space size={16} direction="vertical">
                                {_.isEqual(UserService.level.level, 0) ? (
                                    <>
                                        <Typography as="div" size={14} color="#000000">
                                            {t("Повторите попытку завтра.")}
                                            <br />
                                            {t("Чтобы увеличить лимит обратитесь в чат")}
                                        </Typography>
                                        <ButtonChatSupport type="primary" text="Написать в чат" />
                                    </>
                                ) : (
                                    <>
                                        <Typography as="div" size={14} color="#000000">
                                            {t("Повторите попытку завтра. Чтобы увеличить лимит")}
                                            <br />
                                            {t("обратитесь к персональному менеджеру")}
                                        </Typography>
                                        <Button onClick={onClickWhatsApp} type="primary">
                                            {t("Написать в WhatsApp")}
                                        </Button>
                                    </>
                                )}
                            </Space>
                        }
                    />
                );
            } else {
                contextModal?.setChildren(<Error />);
                setDisable(false);
            }
            contextModal?.setVisible(true);
        }
    };

    useEffect(() => {
        let name: string;

        switch (type) {
            case 4:
                name = "1 день";
                break;
            case 5:
                name = "5 дней";
                break;
            case 11:
                name = "1 час";
                break;
            default:
                name = "3 часа";
                break;
        }

        setState(name);
    }, [type]);

    return (
        <Typography as="div" className="bl-numbers-list-info">
            {_.isEqual(gridType, "kanban") && _.gte(contextLayout?.width, 768) ? (
                <Space size={16}>
                    <Phone category={category} phone={phone} />
                    <Description
                        type={type}
                        phone={phone}
                        category={category}
                        tariffName={tariffName}
                        regionName={regionName}
                        minutes={minutes}
                        internet={internet}
                        sms={sms}
                        id={id}
                        filters={filters}
                        Service={Service}
                    />
                    <Typography as="div" className="icons-container">
                        {!PhonesDefaultService.isPaid(type) && (
                            <Typography
                                as="div"
                                className="bl-reservation-list-info-actions-tooltip-container"
                                id={`tooltip-container-${phone}`}
                            >
                                <Tooltip
                                    getPopupContainer={() => {
                                        return document.getElementById(
                                            `tooltip-container-${phone}`
                                        )!;
                                    }}
                                    overlayClassName="toltip-reservation"
                                    title={"Турбо-бронь"}
                                >
                                    <Typography
                                        as="div"
                                        className="gold-bg icon-wrapper lightning"
                                        onClick={onClick}
                                        disabled={disable}
                                    >
                                        {search.get("nps") ? (
                                            <img src={npsImg} alt="" width={26} />
                                        ) : (
                                            <img src={lightning2} width={16} alt="" />
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Typography>
                        )}
                        {UserService.storeCanBookForDelivery && !PhonesBookingService.isPaid(type) && (
                            <Typography
                                as="div"
                                className="bl-reservation-list-info-actions-tooltip-container"
                                id={`tooltip-container-${phone}`}
                            >
                                <Tooltip
                                    getPopupContainer={() => {
                                        return document.getElementById(
                                            `tooltip-container-${phone}`
                                        )!;
                                    }}
                                    overlayClassName="toltip-reservation"
                                    title={"Супербронь"}
                                >
                                    <div
                                        className="gold-bg icon-wrapper lightning"
                                        onClick={onClickSuper}
                                    >
                                        <img src={superPng} alt="" width={32} />
                                    </div>
                                </Tooltip>
                            </Typography>
                        )}
                    </Typography>
                </Space>
            ) : (
                <ListTariffService
                    filters={filters}
                    Service={Service}
                    props={props}
                    onClick={onClick}
                />
            )}
        </Typography>
    );
});

interface IProps {
    filters: IPhonesFiltersRequest;
    props: IPhone;
    Service: PhoneService;
    gridType: string;
    search: URLSearchParams;
}
