import React from "react";
import {useTranslation} from "react-i18next";
import {Icon, Typography, Space} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {IReservation} from "../../../../../../../core/models/Phones";

export default React.memo(function Phone({category, phone, reservation}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-numbers-list-info-phone">
            <Space size={6}>
                <Icon name={category} size="16" />
                <Typography size={24} color="#fff" className="phone">
                    {phone}
                </Typography>
                {reservation?.dealer_id && (
                    <Typography size={14} color="#fff" className="dealer-id">
                        {t("В брони: {dealer}", {dealer: reservation?.dealer_id})}
                    </Typography>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    category: string;
    phone: number;
    reservation?: IReservation;
}
