import React, {useContext, useEffect, useRef, useState} from "react";
import {Typography, Space, Title, Button, Form, Input} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// components
// core
import {ModalContext} from "../../../core/Contexts";
// services
import SimField from "../../fields/sim";
import useFormHandler from "../../../core/hooks/useFormHandler";
import {IReservationsPutRequest} from "../../../core/models/Reservations";
import ReservationsService from "../../../services/ReservationsService";
import Error from "../../modal/error";
import {toDoubleBackspaceCb} from "../../../core/functions/inputNumber";

export default React.memo(function RemoveSim({phone, sim, id}: IProps) {
    const contextModal = useContext(ModalContext);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    const [prefix] = useState("8970199");
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const [hasSimValue, setHasSimValue] = useState(false);

    const onFinish = async ({sim, ...values}: IReservationsPutRequest) => {
        try {
            const simValues = _.values(_.omitBy(sim, _.isNil)).filter((i) => _.size(_.trim(i)));
            setLoading(true);
            await Promise.all([
                ReservationsService.putReservation({
                    ...values,
                    ...(!!_.size(simValues) && _.isEqual(_.size(simValues), 11)
                        ? {sim: prefix + _.join(simValues, "")}
                        : {})
                })
            ]);
            setLoading(false);
            contextModal?.setVisible(false);
        } catch (e: any) {
            setLoading(false);
            contextModal?.setChildren(<Error />);
            contextModal?.setVisible(true);
        }
    };
    const onValuesChange = (value: any, values: any) => {
        if (_.has(value, "sim")) {
            setHasSimValue(!!_.get(value, "sim"));
        }
    };

    useEffect(() => {
        toDoubleBackspaceCb(ref, "sim", formInstance);
    }, []);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-number-remove-info-modal">
                <Space direction="vertical" size={24}>
                    <Title color="#000"> Смена SIM-карты</Title>
                    <Typography as="div" className="sub-title">
                        Для прикрепления номера <span>{phone}</span> к пустой SIM-карте введите ее
                        серийный номер
                    </Typography>
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="remove-sim-reservation"
                        onValuesChange={onValuesChange}
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            sim: _.replace(sim, prefix, ""),
                            id
                        }}
                    >
                        <Form.Item name="id" hidden={true}>
                            <Input value={id} />
                        </Form.Item>
                        <SimField
                            props={{noStyle: true}}
                            prefix={prefix}
                            formInstance={formInstance}
                            hasSimValue={hasSimValue}
                            setHasSimValue={setHasSimValue}
                        />
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldsValue}) => {
                                const {sim} = getFieldsValue();
                                const simValues = _.values(_.omitBy(sim, _.isNil)).filter((i) =>
                                    _.size(_.trim(i))
                                );

                                const formIsComplete =
                                    !!_.size(simValues) && _.isEqual(_.size(simValues), 11);

                                return (
                                    <>
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={loading}
                                        >
                                            Сменить
                                        </Button>
                                    </>
                                );
                            }}
                        </Form.Item>
                    </Form>
                </Space>
            </Typography>
        </div>
    );
});

interface IProps {
    phone: number;
    sim: string;
    id: any;
}
