import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import "./style.scss";
import {LayoutContext} from "../../../../../core/Contexts";
import infinity_ph from "../../../../../images/infinity_phone.png";

export default function InfoCommon({packet_minutes, packet_sms, packet_internet}: Props) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const infinityLogo =
        _.gte(contextLayout?.width, 767) ?? 0 ? (
            <img src={infinity_ph} width={100} height={45} alt="logo" />
        ) : (
            <img src={infinity_ph} width={35} height={16} alt="logo" />
        );

    return (
        <Typography as="div" className="bl-info-common">
            <Row className="bl-info-common-row" gutter={[0, 10]}>
                <Col xs={24} md={24} className="col-info-common-rest">
                    <Typography className="info-common-rest">{t("На все остальное")}</Typography>
                </Col>
                <Space
                    className="bl-info-common-wrapper"
                    direction="horizontal"
                    size={_.gte(contextLayout?.width, 767) ? 12 : 0}
                >
                    <Col className="col-info-packet bg-value-int-gold">
                        <Typography className="info-packet-value">
                            {packet_internet !== 0 ? packet_internet : infinityLogo}
                            <span className="suffix inline">{" ГБ"}</span>
                        </Typography>
                    </Col>
                    <Col className="col-info-packet">
                        <Typography className="info-packet-value " as="div">
                            <Typography className="gold">
                                {packet_minutes !== 0 ? packet_minutes : infinityLogo}
                            </Typography>

                            <span className="suffix inline">{" мин"}</span>
                        </Typography>
                    </Col>

                    <Col className="col-info-packet">
                        <Typography className="info-packet-value " as="div">
                            <Typography className="gold">
                                {packet_sms !== 0 ? packet_sms : infinityLogo}
                            </Typography>

                            <span className="suffix inline">{" sms"}</span>
                        </Typography>
                    </Col>
                </Space>
            </Row>
        </Typography>
    );
}

interface IProps {
    packet_minutes: number;
    packet_sms: number;
    packet_internet: number;
}

type Props = IProps;
