import {useContext} from "react";
import {Breadcrumb, Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// componentns
import Limits from "./limits";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../../../../../core/Contexts";
import _ from "lodash";
import ReservationsService from "../../../../../../services/ReservationsService";
import {observer} from "mobx-react";
import npsIcon from "../../../../../../images/nps_icon.svg";

const defaultOptions = {
    size: 14,
    color: "rgba(255, 255, 255, 0.5)"
};

export default observer(function Description({
    tariffName,
    minutes,
    internet,
    sms,
    sim,
    pay_type,
    regionName,
    additional_data,
    phoneNPS
}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-reservation-list-info-description">
            {!_.isNil(sim) ? (
                <Space direction="vertical" size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 1}>
                    {_.lt(contextLayout?.width ?? 0, 768) ||
                    _.isEqual(ReservationsService.gridType, "kanban") ? (
                        <Typography as="span" color="#fff" size={17} className="tariff-name-mob">
                            {tariffName}
                        </Typography>
                    ) : null}
                    <Breadcrumb separator={<Icon name="dot" size="5" />}>
                        {_.gte(contextLayout?.width ?? 0, 768) &&
                        !_.isEqual(ReservationsService.gridType, "kanban") ? (
                            <Breadcrumb.Item className="tariff-name">
                                <Space size={4}>
                                    <Typography as="span" {...defaultOptions}>
                                        {tariffName}
                                    </Typography>
                                </Space>
                            </Breadcrumb.Item>
                        ) : null}
                        <Breadcrumb.Item className="tariff-name">
                            <Space size={4}>
                                <Typography as="span" {...defaultOptions}>
                                    {regionName}
                                </Typography>
                            </Space>
                        </Breadcrumb.Item>
                        <Limits minutes={minutes} internet={internet} sms={sms} />
                    </Breadcrumb>
                    {!_.isEqual(additional_data?.delivery_type, "esim") &&
                        !_.isEqual(pay_type, "super_link") && (
                            <Typography as="div" {...defaultOptions} className="sim-number">
                                {t("Номер SIM: {sim}", {sim: sim ? sim : "Не указано"})}
                            </Typography>
                        )}
                </Space>
            ) : (
                <>
                    <Breadcrumb separator={<Icon name="dot" size="5" />}>
                        {_.gte(contextLayout?.width ?? 0, 768) && (
                            <Breadcrumb.Item className="tariff-name">
                                <Space size={4}>
                                    <Typography as="span" {...defaultOptions}>
                                        {tariffName}
                                    </Typography>
                                </Space>
                            </Breadcrumb.Item>
                        )}
                        <Limits minutes={minutes} internet={internet} sms={sms} />
                    </Breadcrumb>
                </>
            )}
        </Typography>
    );
});

interface IProps {
    tariffName: string;
    minutes: number;
    internet: number;
    sms: number;
    sim?: string;
    pay_type: string;
    regionName: string;
    additional_data: any;
    phoneNPS?: string;
}
