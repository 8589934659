import {Carousel, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
// core
import {ITariffsAvailable} from "../../../../core/models/Tariffs";
import Plane from "../plane";
// components

export default observer(function Mobile(props: Props[]) {
    const state = props;

    return (
        <Carousel
            speed={100}
            dots={false}
            arrows={false}
            infinite={true}
            adaptiveHeight={true}
            centerMode={true}
            centerPadding="30px"
            slidesToShow={1}
            initialSlide={0}
        >
            {_.chain(state)
                .map(({id, name, price, minutes, sms, internet, group_name}) => (
                    <Typography as="div" key={id}>
                        <Plane
                            group_name={group_name}
                            name={name}
                            subscription_fee={price}
                            packet_minutes={minutes}
                            packet_sms={sms}
                            packet_internet={internet}
                            isMobile={true}
                        />
                    </Typography>
                ))
                .valueOf()}
        </Carousel>
    );
});

interface IProps extends ITariffsAvailable {}

type Props = IProps;
