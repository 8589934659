import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk.svg";
import ok from "../../../../../../../../images/social_ok.svg";
import wa from "../../../../../../../../images/social_wa_c.svg";
import tg from "../../../../../../../../images/social_tg_c.svg";
import vb from "../../../../../../../../images/social_vb_c.svg";
import sk from "../../../../../../../../images/social_sk_c.svg";
import yt from "../../../../../../../../images/social_yt_c.svg";
import ya from "../../../../../../../../images/social_ya.svg";
import tt from "../../../../../../../../images/social_tt.svg";
import nav from "../../../../../../../../images/social_nav_c.svg";
import gmap from "../../../../../../../../images/social_gmap_c.svg";
import yamap from "../../../../../../../../images/social_yamap_c.svg";
import gmail from "../../../../../../../../images/social_gmail_c.svg";
import mail from "../../../../../../../../images/social_mail.svg";
import sp from "../../../../../../../../images/social_sp.svg";
import go from "../../../../../../../../images/social_go.svg";
import kin from "../../../../../../../../images/social_kin.svg";
import gis from "../../../../../../../../images/social_gis_c.svg";
import bzv from "../../../../../../../../images/social_bz_v.svg";
import infinity_bl from "../../../../../../../../images/infinity_bl.svg";
import _ from "lodash";
import {LayoutContext} from "../../../../../../../../core/Contexts";

export default function Desctop({price, group_name, sms, minutes, internet}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const infinityLogo =
        _.gte(contextLayout?.width, 767) ?? 0 ? (
            <img src={infinity_bl} width={35} height={45} alt="logo" />
        ) : (
            <img src={infinity_bl} width={35} height={16} alt="logo" />
        );

    return (
        <Row>
            <Col span={12} className="col-list-service-desctop">
                <Space direction="vertical" size={8}>
                    <Row className="social-icons-row">
                        <Col span={7}>
                            {" "}
                            <img src={wa} width={14} alt="logo" className="social-icon" />
                            <img src={tg} width={14} alt="logo" className="social-icon" />
                            <img src={vb} width={14} alt="logo" className="social-icon" />
                            <img src={sk} width={14} alt="logo" className="social-icon" />
                        </Col>
                        <Col span={17}>
                            {" "}
                            <Typography as="div" className="social-icons-text">
                                {t("Безлимит на Мессенджеры")}
                            </Typography>
                        </Col>
                    </Row>
                    {!_.isEqual(group_name, "Новогодний Безлимит") ? (
                        <>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={vk} width={14} alt="logo" className="social-icon" />
                                    <img src={ok} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Соц сети")}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img
                                        src={yamap}
                                        width={14}
                                        alt="logo"
                                        className="social-icon"
                                    />
                                    <img src={gis} width={14} alt="logo" className="social-icon" />
                                    <img src={gmap} width={14} alt="logo" className="social-icon" />
                                    <img src={nav} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Карты")}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={mail} width={14} alt="logo" className="social-icon" />
                                    <img
                                        src={gmail}
                                        width={14}
                                        alt="logo"
                                        className="social-icon"
                                    />

                                    <img src={ya} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Почту")}
                                    </Typography>
                                </Col>
                            </Row>
                            {_.lte(price, 590) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={tt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Видео")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={bzv} width={16} alt="logo" className="social-icon " />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Безлимит")}
                                    </Typography>
                                </Col>
                            </Row>

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img src={bee} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Билайн")}
                                    </Typography>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            {_.gt(price, 1100) ? (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={vk}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ok}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Соц сети")}
                                        </Typography>
                                    </Col>
                                </Row>
                            ) : null}

                            {_.gt(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={kin}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={go}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ya}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={sp}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на сервисы Яндекса")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={vk}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ok}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Соц сети")}
                                        </Typography>
                                    </Col>
                                </Row>
                            ) : null}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yamap}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={gis}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={nav}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={gmap}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Карты")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={gmail}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={mail}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ya}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Почту")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={tt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Видео")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={bzv} width={16} alt="logo" className="social-icon " />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Безлимит")}
                                    </Typography>
                                </Col>
                            </Row>

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img src={bee} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Билайн")}
                                    </Typography>
                                </Col>
                            </Row>
                        </>
                    )}
                </Space>
            </Col>
            <Col span={12} className="col-list-service-desctop">
                <Typography className="social-rest">{t("На все остальное")}</Typography>
                <Space
                    className="bl-info-common-wrapper"
                    direction="horizontal"
                    size={_.gte(contextLayout?.width, 767) ? 12 : 12}
                >
                    <Col
                        className={`col-info-packet ${
                            _.isEqual(group_name, "Новогодний Безлимит") ? "bg-value-int-gold" : ""
                        } `}
                    >
                        <Typography as="div" className="info-packet-value">
                            <Typography
                                className={
                                    _.isEqual(group_name, "Новогодний Безлимит") ? "dark" : "gold"
                                }
                            >
                                {internet !== 0 ? internet : infinityLogo}
                            </Typography>
                            <span className="suffix inline">{" ГБ"}</span>
                        </Typography>
                    </Col>
                    <Col className="col-info-packet">
                        <Typography className="info-packet-value " as="div">
                            <Typography className="gold">
                                {minutes !== 0 ? minutes : infinityLogo}
                            </Typography>

                            <span className="suffix inline">{" мин"}</span>
                        </Typography>
                    </Col>

                    <Col className="col-info-packet">
                        <Typography className="info-packet-value " as="div">
                            <Typography className="gold">
                                {sms !== 0 ? sms : infinityLogo}
                            </Typography>

                            <span className="suffix inline">{" sms"}</span>
                        </Typography>
                    </Col>
                </Space>
            </Col>
        </Row>
    );
}

interface IProps {
    price: number;
    group_name: string;
    sms: number;
    minutes: number;
    internet: number;
}
