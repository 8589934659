import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react";
import {Block, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

export const NewYear: React.FC = observer(() => {
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/gift");
    };

    useEffect(() => {
        document.querySelectorAll("video").forEach((v) => {
            v.setAttribute("pip", "false");
        });
    }, []);

    return (
        <Block className="bl-widget-new-year" onClick={onClick}>
            <div className="vidContain">
                {_.gte(contextLayout?.width ?? 0, 431) ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `
                    <video
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src="april.webm" type='video/webm; codecs="vp8, vorbis"' />
                </video>`
                        }}
                    />
                ) : (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `
                <video
                autoPlay
                muted
                loop
                playsInline
            >
                <source src="april_mob.webm" type='video/webm; codecs="vp8, vorbis"' />
            </video>`
                        }}
                    />
                )}
            </div>
            <Typography as="div" className="advertising-text">
                Реклама. ООО «Безлимит». ИНН 9725007063. erid: LjN8KK9X4
            </Typography>
        </Block>
    );
});
