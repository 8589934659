import React, {useEffect, useRef, useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import _ from "lodash";
// core
import {IReservation} from "../../../../../../../core/models/Phones";
// components
import Time from "./time";
// services
import PhonesBookingService from "../../../../../../../services/PhonesBookingService";
import ReservationsService from "../../../../../../../services/ReservationsService";
import {observer} from "mobx-react";

export default observer(function Duration({reservation, suffix, pay_type}: IProps) {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [state, setState] = useState(<></>);

    const getDuration = (value: string | undefined) => {
        const eventTime = moment(value).tz("Europe/Moscow").unix();
        const currentTime = moment(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"}))
            .tz("Europe/Moscow")
            .unix();

        const diffTime = eventTime - currentTime;
        return moment.duration(diffTime * 1000, "milliseconds");
    };

    useEffect(() => {
        if (!_.isNil(reservation)) {
            clearTimeout(timeout.current);
            const {removed_at, deleted_at} = reservation;
            const interval = 1000;
            let duration: any = getDuration(removed_at);
            let duration2: any = getDuration(deleted_at);

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                duration2 = moment.duration(duration2 - interval, "milliseconds");

                if (_.gt(duration.asMilliseconds(), 0)) {
                    setState(<Time duration={duration} suffix={suffix} />);
                } else if (_.gte(duration2.asMilliseconds(), 0)) {
                    setState(<Time duration={duration2} suffix={suffix} />);
                } else {
                    ReservationsService.forceUpdate = !ReservationsService.forceUpdate;
                    PhonesBookingService.default.forceUpdate =
                        !PhonesBookingService.default.forceUpdate;
                    clearTimeout(timeout.current);
                }
            }, interval);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [state]);

    return (
        <>
            <Typography as="div" className="duration-container">
                {state}
            </Typography>
        </>
    );
});

interface IProps {
    reservation: IReservation | undefined;
    suffix?: boolean;
    pay_type?: string;
}
