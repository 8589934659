import _ from "lodash";
// core
import {IAxiosConfig, axiosFetchBuilder} from "../core/Axios";
import {IPhonesRequest, IPhonesResponse} from "../core/models/Phones";
// store
import Phones from "../stores/Phones";

enum Path {
    phones = "/phones",
    filters = "/phones/filters"
}

class PhoneService {
    [Symbol.toStringTag] = this.constructor.name;
    protected readonly store: Phones;

    constructor(store: Phones) {
        this.store = store;
    }

    get forceUpdate() {
        return this.store.forceUpdate;
    }

    set forceUpdate(value) {
        this.store.forceUpdate = value;
    }

    get selectedRowKeys() {
        return this.store.selectedRowKeys;
    }

    set selectedRowKeys(value: React.Key[]) {
        this.store.selectedRowKeys = value;
    }

    get selectedRowKeysSize() {
        return _.size(this.store.selectedRowKeys);
    }

    get name() {
        return this.store.name;
    }

    get type() {
        return this.store.type;
    }

    get items() {
        return this.store.items;
    }

    get itemsSize() {
        return _.size(this.store.items);
    }

    get isEqualItemsSizeAndselectedRowKeysSize() {
        return _.isEqual(this.itemsSize, this.selectedRowKeysSize);
    }

    get meta() {
        return this.store.meta;
    }

    switchType = (key: string) => {
        switch (key) {
            case "standard":
                return 9;
            case "paid":
                return 6;
            case "internet":
                return 3;
            default:
                return 6;
        }
    };

    get perPage() {
        return !_.isEqual(this.type, "default") ? 25 : this.store.meta?.perPage ?? 20;
    }

    get totall() {
        return _.isEqual(this.currentPage, 1) ? this.perPage : this.perPage * this.currentPage;
    }

    get totalCount() {
        return this.store.meta?.totalCount ?? 0;
    }

    get currentPage() {
        return this.store.meta?.currentPage ?? 1;
    }

    get hasItems() {
        return _.gt(this.totalCount, this.itemsSize);
    }

    hasLimits({minutes, sms, internet}: {minutes: number; sms: number; internet: number}) {
        return _.gte(minutes, 0) || _.gte(sms, 0) || _.gte(internet, 0);
    }

    setAllSelectedRowKeys() {
        this.store.selectedRowKeys = _.chain(this.items).map("phone").valueOf();
    }

    resetSelectedRowKeys() {
        this.store.selectedRowKeys = [];
    }

    cleanUp() {
        this.store.meta = undefined;
        this.store.items = undefined;
        this.store.selectedRowKeys = [];
    }

    private async getPhones(
        request: IPhonesRequest,
        options: IAxiosConfig = {}
    ): Promise<IPhonesResponse> {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: Path.phones,
            params: {...request, expand: "reservation,tariff,region,mask,priceParams"},
            ...options,
            tag: "update"
        } as IAxiosConfig);

        this.store.meta = res._meta;
        return res;
    }

    async searchPhones(request: IPhonesRequest, options: IAxiosConfig = {}) {
        const res: IPhonesResponse = await this.getPhones(
            {
                ...request
            },
            options
        );
        this.store.items = res.items;
    }

    async updatePhones({loading = false, ...request}: IPhonesRequest) {
        const meta = this.meta!;
        const res: IPhonesResponse = await this.getPhones(
            {
                ...request
            },
            {
                loading
            }
        );
        this.store.items = res.items;
        this.store.meta = meta;
    }

    async loadMorePhones(request: IPhonesRequest) {
        const res: IPhonesResponse = await this.getPhones(
            {...request},
            {
                loading: false,
                loadingBlock: false
            }
        );
        this.store.items = [...this.store.items!, ...res.items];
    }
}

export default PhoneService;
