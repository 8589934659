import React, {useContext} from "react";
import {Block, Col, Collapse, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {LayoutContext} from "../../../../../../../core/Contexts";
import Phone from "../phone";
import Description from "../description";
import {useTranslation} from "react-i18next";
import {IPhonesFiltersRequest} from "../../../../../../../core/models/PhonesFilters";
import {IPhone} from "../../../../../../../core/models/Phones";
import PhoneService from "../../../../../../../services/PhoneService";
import _ from "lodash";
import Mobile from "./mobile";
import Desctop from "./desctop";
import PhonesDefaultService from "../../../../../../../services/PhonesDefaultService";
import up from "../../../../../../../images/arrow-up.png";
import down from "../../../../../../../images/arrow-down.png";
import infinity_bl from "../../../../../../../images/infinity_bl.svg";
import {isIOS} from "react-device-detect";

export default React.memo(function ListTariffService({
    onClick,
    Service,
    filters,
    props: {
        phone,
        type,
        mask: {category},
        tariff: {id, name: tariffName, minutes, internet, sms, price, group_name},
        region: {name: regionName}
    }
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const infinityLogo =
        _.gte(contextLayout?.width, 767) ?? 0 ? (
            <img src={infinity_bl} width={35} height={45} alt="logo" />
        ) : (
            <img src={infinity_bl} width={35} height={16} alt="logo" />
        );

    return (
        <Collapse
            expandIconPosition="end"
            ghost
            expandIcon={({isActive}) => <img src={isActive ? down : up} alt="" width={20} />}
        >
            <Collapse.Panel
                header={
                    <Space
                        size={_.gte(contextLayout?.width, 768) ? 16 : 0}
                        direction={_.gte(contextLayout?.width, 768) ? "horizontal" : "vertical"}
                    >
                        <Phone category={category} phone={phone} />
                        <Description
                            type={type}
                            phone={phone}
                            category={category}
                            tariffName={tariffName}
                            regionName={regionName}
                            minutes={minutes}
                            internet={internet}
                            sms={sms}
                            id={id}
                            filters={filters}
                            Service={Service}
                        />
                        {!PhonesDefaultService.isPaid(type) && (
                            <div className="gold-bg icon-wrapper lightning" onClick={onClick}>
                                <svg className="lightning" width="32" height="32">
                                    <use href={"#lightning1"} />
                                </svg>
                            </div>
                        )}
                    </Space>
                }
                key="1"
            >
                <Typography as="div" className="list-icons">
                    <Block>
                        <Row className="row-list-service">
                            <Col>
                                {_.gte(contextLayout?.width, 768) ?? 0 ? (
                                    <Desctop
                                        price={price}
                                        group_name={group_name}
                                        minutes={minutes}
                                        internet={internet}
                                        sms={sms}
                                    />
                                ) : (
                                    <Mobile
                                        price={price}
                                        group_name={group_name}
                                        minutes={minutes}
                                        internet={internet}
                                        sms={sms}
                                    />
                                )}
                                {_.lte(contextLayout?.width, 768) ?? 0 ? (
                                    <>
                                        <Typography
                                            as="div"
                                            className={isIOS ? "price-ios-rest" : "price-rest"}
                                        >
                                            {t("На все остальное")}
                                        </Typography>
                                        <Space
                                            className="bl-info-common-wrapper"
                                            direction="horizontal"
                                            size={_.gte(contextLayout?.width, 767) ? 12 : 12}
                                        >
                                            <Col
                                                className={`col-info-packet ${
                                                    _.isEqual(group_name, "Новогодний Безлимит")
                                                        ? "bg-value-int-gold"
                                                        : ""
                                                } `}
                                            >
                                                <Typography as="div" className="info-packet-value">
                                                    <Typography
                                                        className={
                                                            _.isEqual(
                                                                group_name,
                                                                "Новогодний Безлимит"
                                                            )
                                                                ? "dark"
                                                                : "gold"
                                                        }
                                                    >
                                                        {internet !== 0 ? internet : infinityLogo}
                                                    </Typography>
                                                    <span className="suffix inline">{" ГБ"}</span>
                                                </Typography>
                                            </Col>
                                            <Col className="col-info-packet">
                                                <Typography className="info-packet-value " as="div">
                                                    <Typography className="gold">
                                                        {minutes !== 0 ? minutes : infinityLogo}
                                                    </Typography>

                                                    <span className="suffix inline">{" мин"}</span>
                                                </Typography>
                                            </Col>

                                            <Col className="col-info-packet">
                                                <Typography className="info-packet-value " as="div">
                                                    <Typography className="gold">
                                                        {sms !== 0 ? sms : infinityLogo}
                                                    </Typography>

                                                    <span className="suffix inline">{" sms"}</span>
                                                </Typography>
                                            </Col>
                                        </Space>
                                        <Typography
                                            as="div"
                                            className={isIOS ? "price-ios" : "price-android"}
                                        >
                                            {t("Абонентская плата {price} ₽", {
                                                price
                                            })}
                                        </Typography>
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </Block>
                </Typography>
            </Collapse.Panel>
        </Collapse>
    );
});

interface IProps {
    filters: IPhonesFiltersRequest;
    props: IPhone;
    Service: PhoneService;
    onClick: () => Promise<void>;
}
