import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Typography, Row, Col, Collapse, Button, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {isIOS} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import "./style.scss";
// components
import {INumbers} from "../../../../../../../core/models/Phones";
import Item from "./item";
import aDownImg from "../../../../../../../images/aDown.svg";
import {ModalContext} from "../../../../../../../core/Contexts";
import ReservationsService from "../../../../../../../services/ReservationsService";
import UserService from "../../../../../../../services/UserService";
import Success from "../../../../../../modal/success";
import Error from "../../../../../../modal/error";
import ButtonChatSupport from "../../../../../delivery/buttonChatSupport";

const NumberCollapse: React.FC<IProps> = observer(
    ({items, openPanels, setOpenPanels, withShimmer = false, InitCollection}) => {
        const {t} = useTranslation();
        const contextModal = useContext(ModalContext);
        const navigate = useNavigate();

        const onClickWhatsApp = () => {
            (
                window.open(`https://wa.me/7${UserService.personalManager.phone}`, "_blank") as any
            ).focus();
        };

        const onCancel = () => {
            contextModal?.setVisible(false);
            contextModal?.setCssClass("");
        };

        const onClickLightning = async (phone: number) => {
            try {
                const filter =
                    localStorage.getItem("isFavorite") === "collection"
                        ? "collection"
                        : localStorage.getItem("isFavorite") === "true"
                        ? "favorite"
                        : "professional";

                await ReservationsService.postReservationTurbo({
                    phone: phone.toString(),
                    additional_data: JSON.stringify({
                        source: "store_web",
                        filter
                    })
                });

                contextModal?.setChildren(
                    <>
                        <Row justify={"center"}>
                            <Space size={16} direction="vertical" align="center">
                                <Success
                                    title={
                                        <Typography as="span">
                                            {t("Номер успешно забронирован")}
                                        </Typography>
                                    }
                                />
                                <Space size={16} direction="vertical" align="center">
                                    <Button
                                        onClick={() => {
                                            navigate("/reservation");
                                            onCancel();
                                        }}
                                        type="primary"
                                    >
                                        {t("Перейти в брони")}
                                    </Button>
                                    <Row justify={"center"}>
                                        <Button
                                            onClick={() => {
                                                onCancel();
                                                InitCollection();
                                            }}
                                            type="text"
                                        >
                                            {t("Закрыть")}
                                        </Button>
                                    </Row>
                                </Space>
                            </Space>
                        </Row>
                    </>
                );
                setTimeout(() => {
                    const myClose = document.getElementsByClassName("ant-modal-close-x")[0];

                    const handleClickOnce = function () {
                        InitCollection();
                        myClose.removeEventListener("click", handleClickOnce);
                    };

                    myClose.addEventListener("click", handleClickOnce);
                }, 100);
            } catch (e: any) {
                if (_.isEqual(e.response?.status, 430)) {
                    contextModal?.setChildren(
                        <Error
                            title="Вы превысили лимит"
                            message={
                                <Space size={16} direction="vertical">
                                    {_.isEqual(UserService.level.level, 0) ? (
                                        <>
                                            <Typography as="div" size={14} color="#000000">
                                                {t("Повторите попытку завтра.")}
                                                <br />
                                                {t("Чтобы увеличить лимит обратитесь в чат")}
                                            </Typography>
                                            <ButtonChatSupport
                                                type="primary"
                                                text="Написать в чат"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Typography as="div" size={14} color="#000000">
                                                {t(
                                                    "Повторите попытку завтра. Чтобы увеличить лимит"
                                                )}
                                                <br />
                                                {t("обратитесь к персональному менеджеру")}
                                            </Typography>
                                            <Button onClick={onClickWhatsApp} type="primary">
                                                {t("Написать в WhatsApp")}
                                            </Button>
                                        </>
                                    )}
                                </Space>
                            }
                        />
                    );
                } else {
                    contextModal?.setChildren(<Error />);
                }
            }
            contextModal?.setVisible(true);
        };

        return (
            <Typography
                as="div"
                className={`${"bl-collection-collapse"} ${withShimmer ? "locked" : ""}`}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Collapse
                            activeKey={openPanels}
                            onChange={setOpenPanels}
                            defaultActiveKey={["1"]}
                            accordion
                            expandIconPosition="end"
                            expandIcon={({isActive}) => (
                                <img src={aDownImg} width={18} alt="logo" />
                            )}
                        >
                            {_.chain(items)
                                .map((items, key) => {
                                    return (
                                        <Collapse.Panel
                                            className="colapse-mobile"
                                            header={
                                                <>
                                                    <Typography as="div">
                                                        <Typography
                                                            as="div"
                                                            className={`${
                                                                isIOS ? "is-ios" : "phone"
                                                            } ${withShimmer ? "shimmer text" : ""}`}
                                                        >
                                                            {items.phone}
                                                        </Typography>
                                                    </Typography>
                                                    <div
                                                        className={`${
                                                            withShimmer ? "shimmer btn" : ""
                                                        }${"  gold-bg icon-wrapper lightning "}`}
                                                        onClick={() =>
                                                            onClickLightning(items.phone)
                                                        }
                                                    >
                                                        <svg
                                                            className="lightning"
                                                            width="32"
                                                            height="32"
                                                        >
                                                            <use href={"#lightning1"} />
                                                        </svg>
                                                    </div>
                                                </>
                                            }
                                            key={items.phone + key}
                                        >
                                            <Item
                                                phone={items.phone}
                                                name={items.tariff.name}
                                                connection_price={items.tariff.price}
                                                packet_internet={items.tariff.internet}
                                                packet_minutes={items.tariff.minutes}
                                                sms={items.tariff.sms}
                                                region={items.region?.name}
                                                group_name={items.tariff.group_name}
                                            />
                                        </Collapse.Panel>
                                    );
                                })
                                .valueOf()}
                        </Collapse>
                    </Col>
                </Row>
            </Typography>
        );
    }
);

interface IProps {
    items: INumbers[] | undefined;
    InitCollection: () => Promise<void>;
    openPanels: any;
    setOpenPanels: any;
    withShimmer?: boolean;
}

export default React.memo(NumberCollapse);
